.user-item {
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  }
  
  .user-item-enter {
    transform: translateY(-20px);
    opacity: 0;
  }
  
  .user-item-enter-active {
    transform: translateY(0);
    opacity: 1;
  }
  