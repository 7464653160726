@keyframes jump {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-50px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

.jumping {
  animation: jump 0.9s ease-in-out;
}
@keyframes fade {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.5);
  }
}

.fading-emoji {
  animation: fade 5s ease-in-out forwards;
}
